import { useLocation } from 'react-router-dom';
import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import React from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import MaxWidth from '../Layout/MaxWidth';
import TrendButton from '../ui/Button';
import { smallCaps } from '../ui/Headings';
import { activeSegment, smallSection } from './UI/Form';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';
import { DynamicContentRenderer } from '../ContentEditor/DynamicContentRenderer';
import { theme } from '../Theme';

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
`;

const actionWrapper = css`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    flex: 1 1 calc(50% - 1em);
  }
`;

const TextLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.blue};
  text-align: right;
  font-size: 0.875em;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

const LogInPageMaxWidth = styled(MaxWidth)`
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;

  .login-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;

    ${theme.below.lg} {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

function LogInPage() {
  const { routes } = useShopConfig();
  const location = useLocation();
  let redirect = '/my-pages';
  if (location?.search?.indexOf('redirect=') > -1) {
    redirect = location.search.split('redirect=')[1];
  }
  return (
    <>
      <DynamicContentRenderer categoryId={2057} />
      <LogInPageMaxWidth>
        <Intl>{t => <Head data={{ title: t('Login') }} />}</Intl>
        <LogInFormProvider redirect={redirect}>
          {({ globalError, isSubmitting, isValid }) => (
            <section className={cx(smallSection, activeSegment)}>
              <header className="login-header">
                <h1 className={smallCaps} style={{ marginBottom: 0 }}>
                  {t('Login')}
                </h1>

                <TextLink
                  to={routes.signup.path + '?redirect=' + redirect}
                  className="signup-link"
                >
                  {t('Not yet a member? Sign Up')}
                </TextLink>
              </header>
              <Input type="email" name="email" label={t('E-mail address')} />
              <Input type="password" name="password" label={t('Password')} />

              {globalError && (
                <GlobalError style={{ marginBottom: '2em' }}>
                  {globalError}
                </GlobalError>
              )}

              <section className={actionWrapper}>
                <StyledTrendButton
                  type="submit"
                  disabled={!isValid}
                  loading={isSubmitting}
                  loadingText={t('Hold on a moment...')}
                >
                  {t('Log in')}
                </StyledTrendButton>
                <TextLink to={routes.forgotPassword.path}>
                  {t('Forgot password')}?
                </TextLink>
              </section>
            </section>
          )}
        </LogInFormProvider>
      </LogInPageMaxWidth>
    </>
  );
}

export default LogInPage;
